
import { mapState } from "vuex";
import { RESET_PASSWORD, LOG_ERROR } from "@/store/actions.type";
import { required, minLength } from "@vuelidate/validators";
import passwordComplexity from "@/common/passwordComplexity.validator";
import useVuelidate from "@vuelidate/core";
import { defineComponent, State } from "vue";

export default defineComponent({
  name: "CSResetPassword",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      password: "",
      passwordReset: false,
    };
  },
  methods: {
    onSubmit() {
      if (this.v$.$invalid === false) {
        this.$store
          .dispatch(RESET_PASSWORD, {
            token: this.$route.params.token,
            password: this.password,
          })
          .then((data) => {
            if (data.succeeded === true) {
              this.passwordReset = true;
            } else {
              this.$store.dispatch(LOG_ERROR, data.errors);
            }
          });
      }
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
        "field__input custom-field__input": true,
      };
    },
  },
  computed: {
    ...mapState({
      errors: (state) => (state as State).auth.errors,
    }),
  },
  validations: {
    password: { required, min: minLength(8), passwordComplexity },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(LOG_ERROR, []);

    next();
  },
});
