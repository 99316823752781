export default password => {
    if (typeof password === "undefined" || password === null || password === "")
        return false;

    if (false === /[A-Z]/.test(password))
        return false;

    if (false === /[a-z]/.test(password))
        return false;

    if(false === /\d/.test(password))
        return false;

    return true;
};